import { Injectable } from '@angular/core';
import * as stringify from 'json-stable-stringify';
import {
  ApiEntityAction,
  ApiEntity,
  ApiEntityId,
  ApiEntityResponse,
  ApiObject, newApiEntityRequest,
} from '../../api/datacleanuptool-api.model';
import { ApiUrlService } from '../services/api-url.service';
import { createPromise, OwnedPromise } from '../util/promise-util';

import { BaseMutableDataWrapper } from './mutable-data';
import {from, Observable} from "rxjs";

/**
 * The Client Entity Manager, heart of the client entity system. This service manages all the entities that are in use
 * in the system and handles requesting new entities from the server. Controllers may request entities directly from
 * the manager using [entityFor] and passing in a request object built by a client entity.
 */
@Injectable()
export class ClientEntityManager {
  /**
   * Holds a mapping of the type of [ApiEntityId]s to their corresponding client entity wrapper classes. Used for
   * automatically instantiating entities from server-provided identifiers.
   */
  public static idTypeToEntityFactoryMap = new Map<ApiEntityId['@type'], IClientEntityClass<any, any, any>>();

  constructor(
    private apiService: ApiUrlService,
  ) {}

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Public Interface

  /**
   * Schedules the given entity requests for fetching from the server, if they have not already been fetched or are
   * currently loading.
   */
  preloadEntities(...requests: Array<ClientEntityRequest<any, any, any>>) {
    for (const request of requests) {
      this.entityFor(request);
    }
  }

  /**
   * Schedules the given entity ids for fetching from the server, if they have not already been fetched or are
   * currently loading.
   */
  preloadEntityIds(...ids: ApiEntityId[]) {
    for (const id of ids) {
      this.entityForId(id);
    }
  }

  /**
   * Resolves a [ClientEntityRequest] into an entity and ensures that it is either fetched or queued for fetching. This
   * is the main entry point into the entity system, and should be used for most lookups.
   */
  entityFor<
    TId extends ApiEntityId,
    TApiEntity extends ApiEntity<TId>,
    TClientEntity extends BaseClientEntity<TId, TApiEntity>
  >(
    request: ClientEntityRequest<TId, TApiEntity, TClientEntity>
  ): TClientEntity {
    return this.ensureEntity<TId, TApiEntity, TClientEntity>(request.id).ensureFetched();
  }

  /**
   * Resolves a raw [ApiEntityId] into an entity and ensure that it is either fetched or queued for fetching. This method
   * requires that the caller pass in all the type parameters needed and should only be used in advanced use cases.
   */
  entityForId<
    TId extends ApiEntityId,
    TApiEntity extends ApiEntity<TId>,
    TClientEntity extends BaseClientEntity<TId, TApiEntity>
    >(
    id: ApiEntityId
  ) : TClientEntity {
    return this.ensureEntity<TId, TApiEntity, TClientEntity>(id).ensureFetched();
  }

  /**
   * Performs an entity action on the server and returns the response of the call as a promise. This version of the
   * method is for general actions that don't return single entities. Use [performEntityAction] for those.
   */
  async performAction<
    TResponse extends ApiObject
  >(
    action: ApiEntityAction<TResponse>
  ): Promise<TResponse> {
    const result = (await this.queueRequest([ action ], [])
        .catch(error => {
        throw error;
        })
  )[0] as TResponse;
    if (typeof(result) !== 'object') {
      throw new Error('Action response for ' + JSON.stringify(action) + ' was not an object; instead got ' + result);
    }
    return result;
  }

  performApiAction$<
    TResponse extends ApiObject
    >(
    action: ApiEntityAction<TResponse>
  ): Observable<TResponse> {

    return from(this.performAction(action));
  }

  getDirectELumenImportAction$<TResponse extends ApiObject>(requestType: String, collegeSlug: String, year: Number, endMonth: String, endDay: Number, endYear: Number): Observable<TResponse>{
    return this.apiService.getELumenDirectImport(requestType, collegeSlug, year, endMonth, endDay, endYear);
  }

  getDirectCurriqunetImportAction$<TResponse extends ApiObject>(requestType: String, collegeSlug: String, year: Number): Observable<TResponse>{
    return this.apiService.getCurriqunetDirectImport(requestType, collegeSlug, year);
  }

  getDirectImportTypeAction$(collegeSlug: String): Observable<String>{
    return this.apiService.getDirectImportType(collegeSlug);
  }

  /**
   * Performs an action that returns an entity id and resolves the entity upon completion of the request.
   */
  performEntityAction<
    TId extends ApiEntityId,
    TApiEntity extends ApiEntity<TId>,
    TClientEntity extends BaseClientEntity<TId, TApiEntity>
  >(
    entityType: IClientEntityClass<TId, TApiEntity, TClientEntity>,
    action: ApiEntityAction<TId>
  ): Promise<TClientEntity> {
    return this.performAction<TId>(action).then(id => this.entityForId<TId, TApiEntity, TClientEntity>(id))
      .catch(error => {
        console.log("uncaught promise here")
        throw error;
      });
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Internal Methods

  /**
   * The primary entity map. Maps the string version of an entity id to the entity wrapper.
   */
  private entityIdMap = new Map<string, AnyClientEntity>();

  /**
   * Map of entities currently being explicitly fetched.
   */
  private pendingEntityIdMap = new Map<string, PendingEntityRequest>();

  /**
   * Holds the next request for entities that will be sent to the server. This is done to batch requests instead of
   * sending one per entity.
   */
  private _nextRequest?: PendingEntityRequest;

  /**
   * Ensures that there is an entity wrapper for the given identifier. Does not request that the entity be loaded,
   * just that the wrapper exists and is stored in this manager.
   */
  ensureEntity<
    TId extends ApiEntityId,
    TApiEntity extends ApiEntity<TId>,
    TClientEntity extends BaseClientEntity<TId, TApiEntity>
  >(
    id: ApiEntityId
  ) {
    const idString = stringForId(id);
    const existingEntity = this.entityIdMap.get(idString);

    if (existingEntity) {
      return existingEntity as TClientEntity;
    } else {
      const entityClass = ClientEntityManager.idTypeToEntityFactoryMap.get(id['@type']);
      if (! entityClass) {
        throw new Error('No ClientEntity registered for id type: ' + id['@type']);
      }
      const newEntity = new entityClass(
        this,
        () => this.queueRequest([], [id]),
        id
      );
      this.entityIdMap.set(idString, newEntity);
      return newEntity as TClientEntity;
    }
  }

  private entityRequestCount: number = 0;

  /**
   * Ensures that there is an entity request that's queued for resolution. Doesn't actually request anything, but the
   * request that it returns will be sent to the server sometime soon.
   */
  private ensureNextRequest() {
    if (! this._nextRequest) {
      const ourRequest = this._nextRequest = {
        actions: [],
        requestedIds: new ApiEntityIdSet(),
        promise: createPromise()
      };

      // Wait until the next animation frame to actually send the request
      requestAnimationFrame(async() => {
        // Assuming our request is still the current one (it really should be), remove it from the manager so no
        // more ids or actions get added to it after we send it.
        if (ourRequest == this._nextRequest) {
          delete this._nextRequest;
        }

        // Create the actual API Request
        const apiRequest = newApiEntityRequest({
          actions: ourRequest.actions,
          requestedIds: ourRequest.requestedIds.toArray(),
          currentIds: Array.from(this.entityIdMap.values()).map(e => e.id)
        });

        try {
          // Logging
          this.entityRequestCount ++;
          //if (isDevMode()) {
          if(true){
            console.info(`EntityRequest (${this.entityRequestCount})`, apiRequest);
          }

          // Perform the actual entity request
          const response: ApiEntityResponse = await this.apiService.sendMessage(
            apiRequest
          ).toPromise()
            .catch(error => {
              console.error("error calling api service")
              throw error;
            });


          // Unmark entities as pending
          apiRequest.requestedIds.forEach(id => {
            delete this.pendingEntityIdMap[stringForId(id)];
          });

          // Unload invalidated entities
          response.invalidatedIds.forEach(id => {
            const entity = this.entityIdMap.get(stringForId(id));
            if (entity) {
              entity.unload();
            }
          });

          // Injest all the entities
          response.entities.forEach(entity => this.handleApiEntity(entity));

          // Resolve the promise for the response
          ourRequest.promise.resolve(response);
        } catch (e) {

          // Unmark entities as pending
          apiRequest.requestedIds.forEach(id => {
            delete this.pendingEntityIdMap[stringForId(id)];
          });

          // Reject the request promise
          ourRequest.promise.reject(e);
        }
      });
    }

    return this._nextRequest;
  }

  /**
   * Handles new api entity data by applying it to the relevant entity.
   */
  private handleApiEntity(
    apiEntity: ApiEntity<any>
  ) {
    if (ClientEntityManager.idTypeToEntityFactoryMap.has(apiEntity.id['@type'])) {
      // Note: we only apply the api data if we know about the type of the entity. Unknown types are ignored. This is
      // because entities are only added to our map when the TypeScript class is imported by a user of it. If no one
      // imports the class, we won't know about it, and that's OK, because if it wasn't imported, it probably isn't
      // needed by anything.
      this.ensureEntity(apiEntity.id).applyExternalData(apiEntity);
    }
  }

  /**
   * Requests that the given actions and entity ids be added to the next request that will be sent to the server and
   * returns a promise for when they're loaded.
   */
  protected async queueRequest(
    actions: ApiEntityAction<any>[],
    requestedIds: ApiEntityId[]
  ): Promise<ApiObject[]> {
    const request = this.ensureNextRequest();
    const actionIndex = request.actions.length;
    const actionCount = actions.length;

    // Add the requested IDs to the next request and mark them pending
    requestedIds.forEach(id => {
      this.pendingEntityIdMap[stringForId(id)] = request;
      request.requestedIds.add(id);
    });

    // Add the actions to the next request
    actions.forEach(a => request.actions.push(a));

    // Wait for the next request to be finished
    const response = await request.promise.promise
      .catch(error => {
      throw error;
    });

    // And extract our action results
    return response.actionResponses.slice(actionIndex, actionIndex + actionCount);
  }
}

/**
 * Interface representing an entity class. Used for registering known entities so we can automatically instantiate them
 * given an ID.
 */
export interface IClientEntityClass<
  TId extends ApiEntityId,
  TApiEntity extends ApiEntity<TId>,
  TClientEntity extends BaseClientEntity<TId, TApiEntity>
> {
  new(
    entityManager: ClientEntityManager,
    requestFetch: () => void,
    id: ApiEntityId
  ): TClientEntity;
}

/**
 * Represents a request for entities that is either in flight or is the next request to be sent.
 */
interface PendingEntityRequest {
  actions: ApiEntityAction<any>[];
  requestedIds: ApiEntityIdSet;

  promise: OwnedPromise<ApiEntityResponse>;
}

/**
 * Converts an entity id to string, caching the result in the object itself. Future calls to this function for an id
 * are very fast, suitable for use in angular change-detection-called code.
 */
export function stringForId(id: ApiEntityId) {
  if (!('_json' in id)) {
    // Convert the id to JSON and then store that as a property in the object
    Object.defineProperty(
      id,
      '_json',
      {
        value: stringify(id),
        writable: false,
        enumerable: false,
        configurable: false
      }
    );
  }

  return (id as any)._json;
}

/**
 * A set of [ApiEntityId], kept unique using [stringForId] and exposing an array of the actual IDs for convenience.
 */
export class ApiEntityIdSet<TId extends ApiEntityId = ApiEntityId> {
  private idStringSet = new Set<string>();
  private idArray: TId[] = [];

  has(id: TId) {
    return this.idStringSet.has(stringForId(id));
  }

  add(id: TId) {
    if (! this.has(id)) {
      this.idStringSet.add(stringForId(id));
      this.idArray.push(id);
    }
    return this;
  }

  delete(id: TId) {
    if (this.has(id)) {
      const idString = stringForId(id);

      this.idStringSet.delete(idString);
      this.idArray.splice(this.idArray.findIndex(i => stringForId(i) === idString), 1);

      return true;
    }

    return false;
  }

  get size() {
    return this.idStringSet.size;
  }

  toArray() {
    return this.idArray.slice();
  }

  [Symbol.iterator]() {
    return this.idArray[Symbol.iterator];
  }

  addAll(ids: Iterable<TId>) {
    for (const id of ids) {
      this.add(id);
    }
  }
}

/**
 * Client Entity Decorator. This should be applied to all client entity classes. It registers the entity with the
 * entity manager. The parameter must be the type discriminator for the id type of the entity. This is type-safe, and
 * will fail if the value doesn't match the declared id type of the entity.
 */
export function ClientEntity<
  TIdType extends ApiEntityId['@type'],
  TId extends ApiEntityId & { '@type': TIdType }
  >(idType: TIdType) {
  return function(
    constructor: IClientEntityClass<
      TId,
      ApiEntity<TId>,
      BaseClientEntity<TId, ApiEntity<TId>>
      >
  ) {
    ClientEntityManager.idTypeToEntityFactoryMap.set(idType, constructor);
  };
}

/**
 * A "request" for a particular entity by it's id. This class exists to bind the types generated from API with the client
 * entity type. This way, users of entities don't have to pass generic parameters to the entity manager when request
 * entities.
 */
export class ClientEntityRequest<
  TId extends ApiEntityId,
  TApiEntity extends ApiEntity<TId>,
  TClientEntity extends BaseClientEntity<TId, TApiEntity>
> {
  constructor(
    // Used soley for type inference
    entityClass: IClientEntityClass<TId, TApiEntity, TClientEntity>,
    public readonly id: TId
  ) {}
}

/**
 * Base class for client entities. Provides some helper methods to make resolution of related entities easier.
 */
export abstract class BaseClientEntity<
  TId extends ApiEntityId,
  TApiEntity extends ApiEntity<TId>
> extends BaseMutableDataWrapper<TApiEntity>{

  /**
   * A JSON-serialized version of the data this object wraps, created when the data was last updated externally. By
   * storing this, we can easily determine if the data has been mutated since it was last updated. Useful for various
   * business logic, like prompting the user to save changes, but only if they have made any.
   */
  private _dataJson: string | undefined;

  constructor(
    public readonly entityManager: ClientEntityManager,
    private readonly requestFetch: () => void,
    public readonly id: TId,
  ) {
    super();
  }

  get entityClass(): IClientEntityClass<TId, TApiEntity, this> {
    return ClientEntityManager.idTypeToEntityFactoryMap.get(this.id['@type']);
  }

  get idString() {
    return stringForId(this.id);
  }

  protected fetchData() {
    this.requestFetch();
  }

  // /**
  //  * Delegate for entity resolution for entity requests
  //  */
  // protected entityFor<
  //   TId extends ApiEntityId,
  //   TApiEntity extends ApiEntity<TId>,
  //   TClientEntity extends BaseClientEntity<TId, TApiEntity>
  //  >(
  //   request: ClientEntityRequest<TId, TApiEntity, TClientEntity>
  // ): TClientEntity {
  //   return this.entityManager.entityFor(request);
  // }

  /**
   * Performs an action that returns this entity, and verifies that the ID matches.
   */
  protected async performActionReturningThis(
    action: ApiEntityAction<TId>
  ) {
    const resultId = await this.entityManager.performAction(action)
      .catch(error => {
        console.error("error performing action")
        throw error;
      });

    if (stringForId(resultId) !== this.idString) {
      throw new Error(
        `Result of action ${JSON.stringify(action)} should have returned ${this.idString}; instead it returned ${stringForId(resultId)}`
      );
    }
    return this;
  }

  get isChanged() {
    return this.isLoaded && JSON.stringify(this.data) === this._dataJson;
  }

  protected onDataChanged() {
    this._dataJson = JSON.stringify(this.data);
  }

  toString() {
    return `${(this as Object).constructor.name}(id="${this.idString}")`;
  }
}

/**
 * Type representing any kind of client entity.
 */
export type AnyClientEntity = BaseClientEntity<any, any>;
